import { combineReducers } from '@reduxjs/toolkit';

import { reducer as notificationReducer } from 'src/slices/notification';

import { acceptWelcomeMessageReducer } from './accept-welcome-message-reducer';
import { accountTypeListReducer } from './account-type-reducer';
import { addressUpdateReducer, addressCreateReducer } from './address-reducer';
import { addressValidatorReducer } from './address-validator-reducer';
import { updateDepreciationReducer } from './asset-depreciation-reducer';
import { assetGetSettingsReducer } from './asset-get-settings-reducer';
import { authUserUploadPhotoReducer } from './auth-reducer';
import {
  cartOpenDrawerReducer,
  cartProductAddReducer,
  cartProductListReducer,
  cartProductRemoveReducer,
} from './cart-reducer';
import { catalogCountryGetReducer } from './catalog-country-reducer';
import {
  catalogProductInfiniteReducer,
  catalogProductRemoveReducer,
  catalogProductAddReducer,
  catalogProductListReducer,
} from './catalog-product-reducer';
import {
  catalogListReducer,
  catalogDetailsReducer,
  catalogUpdateReducer,
  catalogCreateReducer,
  catalogListInfiniteReducer,
  catalogDuplicateReducer,
} from './catalog-reducer';
import { categoryListReducer } from './category-reducer';
import { colorListInfiniteReducer } from './color-reducer';
import { currencyReducer } from './currency-reducer';
import {
  dashboardStateReducer,
  dashboardAvgOrderReducer,
  dashboardEmployeeCountReducer,
  dashboardOrderByDepartmentReducer,
  dashboardOrderByMonthReducer,
  dashboardTotalProductsOrderedReducer,
  dashboardTotalSpentStateReducer,
  dashboardLatestRegistrantsReducer,
} from './dashboard-reducer';
import { departmentListReducer } from './department-reducer';
import {
  employeeDetailsReducer,
  employeeOrderListReducer,
  employeeAssetListReducer,
  employeeDeleteReducer,
  employeeOffboardAssetListReducer,
  employeeBulkUploadReducer,
  employeeCreateReducer,
  employeeListInfiniteReducer,
  EmployeeUpdateReducer,
  employeeListReducer,
} from './employee-reducer';
import {
  employerAnnouncementReducer,
  employerAnnouncementUpdateReducer,
  employerAnnouncementCreateReducer,
  employerAnnouncementUploadImageReducer,
  employerAnnouncementImageReducer,
} from './employer-announcement-reducer';
import {
  employerCreateReducer,
  employerUpdateReducer,
  employerListReducer,
  employerSettingsUpdateReducer,
  employerDetailsReducer,
} from './employer-reducer';
import {
  generateTokenListReducer,
  generateTokenCreateReducer,
  generateTokenDeleteReducer,
} from './generate-token-reducer';
import { helpInfoReducer } from './help-info-reducer';
import {
  invitationResendUnacceptedReducer,
  invitationResendSingleEmployeeReducer,
} from './invitation-reducer';
import {
  offboardDetailsReducer,
  offboardUpdateStatusReducer,
  offboardsListReducer,
  offboardCreateReducer,
  offboardDeleteReducer,
} from './offboard-reducer';
import { offboardableCountriesGetReducer } from './offboardable-countries-reducers';
import { orderCheckoutReducer } from './order-checkout-reducer';
import { orderListReducer } from './order-reducer';
import { pimProductsToCatalogReducer } from './pim-product-reducers';
import { placeOrderSubmitReducer } from './place-order-submit-status-reducer';
import { productImageDeleteReducer } from './product-image-reducer';
import {
  productListReducer,
  productDetailsReducer,
  productListAdminReducer,
  productsToCatalogReducer,
  productCreateReducer,
  productDeleteReducer,
  productUpdateReducer,
} from './product-reducer';
import {
  productVariantSelectReducer,
  productVariantRemoveReducer,
  productVariantDetailsReducer,
  productVariantUpdateReducer,
} from './product-variant-reducer';
import { requestReturnProductSubmitReducer } from './request-return-product-submit';
import { settingsListReducer } from './settings-reducer';
import { supplierListInfiniteReducer } from './supplier-reducer';
import { topUpUpdateReducer, topUpListReducer } from './topup-reducer';
import { userStatusUpdateReducer } from './user-status-reducer';

const rootReducer = combineReducers({
  notifications: notificationReducer,
  // employer-announcement
  employerAnnouncement: employerAnnouncementReducer,
  employerAnnouncementCreate: employerAnnouncementCreateReducer,
  employerAnnouncementUpdate: employerAnnouncementUpdateReducer,
  employerAnnouncementUploadImage: employerAnnouncementUploadImageReducer,
  employerAnnouncementImage: employerAnnouncementImageReducer,
  // currency
  currency: currencyReducer,
  // employer
  employerCreate: employerCreateReducer,
  employerUpdate: employerUpdateReducer,
  employerList: employerListReducer,
  employerSettingsUpdate: employerSettingsUpdateReducer,
  employerDetails: employerDetailsReducer,
  // employee checkout
  placeOrderSubmitStatus: placeOrderSubmitReducer,
  // request return product
  requestReturnProductSubmit: requestReturnProductSubmitReducer,
  // employer tenant
  offboardableCountriesGet: offboardableCountriesGetReducer,
  // SETTINGS
  settingsList: settingsListReducer,
  // PRODUCT
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productListAdmin: productListAdminReducer,
  productsToCatalog: productsToCatalogReducer,
  productCreate: productCreateReducer,
  productDelete: productDeleteReducer,
  productUpdate: productUpdateReducer,
  // DEPARTMENT
  departmentList: departmentListReducer,
  // CATEGORY
  categoryList: categoryListReducer,
  // INVITATION
  invitationResendUnaccepted: invitationResendUnacceptedReducer,
  // USER STATUS
  userStatusUpdate: userStatusUpdateReducer,
  // EMPLOYEE
  employeeDetails: employeeDetailsReducer,
  employeeOrderList: employeeOrderListReducer,
  employeeAssetList: employeeAssetListReducer,
  employeeDelete: employeeDeleteReducer,
  employeeOffboardAssetList: employeeOffboardAssetListReducer,
  employeeBulkUpload: employeeBulkUploadReducer,
  employeeCreate: employeeCreateReducer,
  employeeListInfinite: employeeListInfiniteReducer,
  employeeUpdate: EmployeeUpdateReducer,
  employeeList: employeeListReducer,
  // INVITATION,
  invitationResendSingleEmployee: invitationResendSingleEmployeeReducer,
  // OFFBOARD
  offboardDetails: offboardDetailsReducer,
  OffboardUpdateStatus: offboardUpdateStatusReducer,
  offboardsList: offboardsListReducer,
  offboardCreate: offboardCreateReducer,
  offboardDelete: offboardDeleteReducer,
  generateTokenList: generateTokenListReducer,
  generateTokenCreate: generateTokenCreateReducer,
  generateTokenDelete: generateTokenDeleteReducer,
  //ORDER
  orderCheckout: orderCheckoutReducer,
  orderList: orderListReducer,
  //ADDRESS VALIDATOR
  addressValidator: addressValidatorReducer,
  //ASSET
  assetDepreciationUpdate: updateDepreciationReducer,
  assetGetSettings: assetGetSettingsReducer,
  // topup
  topUpUpdate: topUpUpdateReducer,
  topUpList: topUpListReducer,
  //dashboard
  dashboardState: dashboardStateReducer,
  dashboardTotalSpent: dashboardTotalSpentStateReducer,
  dashboardEmployeeCount: dashboardEmployeeCountReducer,
  dashboardAvgOrder: dashboardAvgOrderReducer,
  dashboardTotalProductsOrdered: dashboardTotalProductsOrderedReducer,
  dashboardOrderByDepartment: dashboardOrderByDepartmentReducer,
  dashboardOrderByMonth: dashboardOrderByMonthReducer,
  dashboardLatestRegistrants: dashboardLatestRegistrantsReducer,
  //ADDRESS
  addressUpdate: addressUpdateReducer,
  addressCreate: addressCreateReducer,
  // PIM
  pimProductsToCatalog: pimProductsToCatalogReducer,
  // COLORS
  colorListInfinite: colorListInfiniteReducer,
  // CATALOG
  catalogList: catalogListReducer,
  catalogDetails: catalogDetailsReducer,
  catalogUpdate: catalogUpdateReducer,
  catalogCreate: catalogCreateReducer,
  catalogListInfinite: catalogListInfiniteReducer,
  catalogDuplicate: catalogDuplicateReducer,
  // CATALOG COUNTRY
  catalogCountryGet: catalogCountryGetReducer,
  // CATALOG PRODUCT
  catalogProductInfinite: catalogProductInfiniteReducer,
  catalogProductRemove: catalogProductRemoveReducer,
  catalogProductAdd: catalogProductAddReducer,
  catalogProductList: catalogProductListReducer,
  // SUPPLIER
  supplierListInfinite: supplierListInfiniteReducer,
  // PRODUCT
  productImageDelete: productImageDeleteReducer,
  // ACCOUNT-TYPES
  accountTypeList: accountTypeListReducer,
  // PRODUCT VARIANT
  productVariantSelect: productVariantSelectReducer,
  productVariantRemove: productVariantRemoveReducer,
  productVariantDetails: productVariantDetailsReducer,
  productVariantUpdate: productVariantUpdateReducer,
  // WELCOME MESSAGE
  acceptWelcomeMessage: acceptWelcomeMessageReducer,
  // AUTH
  authUserUploadPhoto: authUserUploadPhotoReducer,
  // HELP-INFO
  helpInfo: helpInfoReducer,
  // CART
  cartOpenDrawer: cartOpenDrawerReducer,
  cartProductList: cartProductListReducer,
  cartProductRemove: cartProductRemoveReducer,
  cartProductAdd: cartProductAddReducer,
});

export default rootReducer;
