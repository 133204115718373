import { z } from 'zod';

export const accountTypeEnum = z.enum([
  'live',
  'onboarding',
  'client_trial',
  'sales_demo',
  'to_be_deleted',
]);

const notificationSchema = z.object({
  notification_name: z.string(),
  is_active: z.boolean(),
});

export const employerStatsValidator = z.object({
  monday_integraiton_enabled: z.boolean(),
  auto_offboarding_enabled: z.boolean(),
  account_type: accountTypeEnum,
  order_count: z.number(),
  repair_count: z.number(),
  offboarding_count: z.number(),
  active_user_count: z.number(),
  total_users: z.number(),
  admin_count: z.number(),
  team_count: z.number(),
  sso_enabled: z.boolean(),
  hr_sync_enabled: z.boolean(),
  slack_integration_enabled: z.boolean(),
  total_warehouse_asset_count: z.number(),
  warehouse_used_asset_count: z.number(),
  warehouse_new_asset_count: z.number(),
  warehouse_asset_count: z.number(),
  department_count: z.number(),
  office_count: z.number(),
  employee_count: z.number(),
  office_countries: z.array(z.string()),
  warehouse_countries: z.array(z.string()),
  notifications: z.object({
    onboarding: z.array(notificationSchema),
    offboarding: z.array(notificationSchema),
    asset_management: z.array(notificationSchema),
    request: z.array(notificationSchema),
    registration: z.array(notificationSchema),
    shipment: z.array(notificationSchema),
  }),
});
