import { default as i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import announcementEN from 'src/locales/en/announcement.json';
import assetManagementEN from 'src/locales/en/asset_management.json';
import cartEN from 'src/locales/en/cart.json';
import commonEN from 'src/locales/en/common.json';
import dashboardEN from 'src/locales/en/dashboard.json';
import decommissioningEN from 'src/locales/en/decommissioning.json';
import integrationsEN from 'src/locales/en/integrations.json';
import navItemsEN from 'src/locales/en/navItems.json';
import OffboardingsEN from 'src/locales/en/offboardings.json';
import officeEn from 'src/locales/en/office.json';
import orderHistoryEN from 'src/locales/en/order_history.json';
import productCatalogueEN from 'src/locales/en/product_catalogues.json';
import profileEN from 'src/locales/en/profile.json';
import registerEN from 'src/locales/en/register.json';
import repairEN from 'src/locales/en/repair.json';
import requestEN from 'src/locales/en/request.json';
import shipmentEN from 'src/locales/en/shipment.json';
import userManagementEN from 'src/locales/en/user_management.json';
import warehouseEN from 'src/locales/en/warehouse.json';
import announcementFR from 'src/locales/fr/announcement.json';
import assetManagementFR from 'src/locales/fr/asset_management.json';
import cartFR from 'src/locales/fr/cart.json';
import commonFR from 'src/locales/fr/common.json';
import dashboardFR from 'src/locales/fr/dashboard.json';
import decommissioningFR from 'src/locales/fr/decommissioning.json';
import integrationsFR from 'src/locales/fr/integrations.json';
import navItemsFR from 'src/locales/fr/navItems.json';
import OffboardingsFR from 'src/locales/fr/offboardings.json';
import officeFR from 'src/locales/fr/office.json';
import orderHistoryFR from 'src/locales/fr/order_history.json';
import productCatalogueFR from 'src/locales/fr/product_catalogues.json';
import profileFR from 'src/locales/fr/profile.json';
import registerFR from 'src/locales/fr/register.json';
import repairFR from 'src/locales/fr/repair.json';
import requestFR from 'src/locales/fr/request.json';
import shipmentFR from 'src/locales/fr/shipment.json';
import userManagementFR from 'src/locales/fr/user_management.json';
import warehouseFR from 'src/locales/fr/warehouse.json';

export const resources = {
  en: {
    profile: profileEN,
    common: commonEN,
    office: officeEn,
    navItems: navItemsEN,
    dashboard: dashboardEN,
    decommissioning: decommissioningEN,
    productCatalogue: productCatalogueEN,
    userManagement: userManagementEN,
    orderHistory: orderHistoryEN,
    assetManagement: assetManagementEN,
    announcement: announcementEN,
    cart: cartEN,
    register: registerEN,
    request: requestEN,
    offboardings: OffboardingsEN,
    shipment: shipmentEN,
    integrations: integrationsEN,
    warehouse: warehouseEN,
    repair: repairEN,
  },
  fr: {
    profile: profileFR,
    common: commonFR,
    office: officeFR,
    navItems: navItemsFR,
    dashboard: dashboardFR,
    decommissioning: decommissioningFR,
    productCatalogue: productCatalogueFR,
    userManagement: userManagementFR,
    orderHistory: orderHistoryFR,
    assetManagement: assetManagementFR,
    announcement: announcementFR,
    cart: cartFR,
    register: registerFR,
    request: requestFR,
    offboardings: OffboardingsFR,
    shipment: shipmentFR,
    integrations: integrationsFR,
    warehouse: warehouseFR,
    repair: repairFR,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    ns: ['profile'],
    react: {
      useSuspense: true,
    },
    resources,
  });

export default i18n;
