import { SVGProps } from 'react';

const NotebookIcon = ({
  height = '11',
  width = '14',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 11"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="M13.3 6.875h-.7V2.062c0-.547-.221-1.071-.615-1.458A2.12 2.12 0 0 0 10.5 0h-7a2.12 2.12 0 0 0-1.485.604A2.044 2.044 0 0 0 1.4 2.062v4.813H.7a.707.707 0 0 0-.495.201.681.681 0 0 0-.205.487v1.375c0 .547.221 1.071.615 1.458A2.12 2.12 0 0 0 2.1 11h9.8a2.12 2.12 0 0 0 1.485-.604c.394-.387.615-.911.615-1.459V7.563a.681.681 0 0 0-.205-.487.707.707 0 0 0-.495-.201ZM2.8 2.062c0-.182.074-.357.205-.486a.706.706 0 0 1 .495-.201h7c.186 0 .364.072.495.201a.681.681 0 0 1 .205.486v4.813H2.8V2.062Zm9.8 6.876a.681.681 0 0 1-.205.486.707.707 0 0 1-.495.201H2.1a.706.706 0 0 1-.495-.201.681.681 0 0 1-.205-.486V8.25h11.2v.688Z"
    />
  </svg>
);

export default NotebookIcon;
